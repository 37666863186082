import React, { Component } from 'react';
import Select from 'react-select'
import { youtubeLink } from '../const/youtube';
// import youtubeLink from '../components/otherlanguage/YouTubeLink'

const languages = [
  //SNS対応国
    { value: 'en', label: 'English' }, //英語
    { value: 'ja', label: '日本語' }, //日本語
    { value: 'es', label: 'Español' },//スペイン語
    { value: 'ru', label: 'русский' },//ロシア語 
    { value: 'fr', label: 'français' },//フランス語
    { value: 'de', label: 'Deutsch' },//ドイツ語
    { value: 'it', label: 'italiano' },//イタリア語
    { value: 'zh', label: '中文' },//中国語
    { value: 'ko', label: '한국어' },//韓国語
    { value: 'ar', label: 'عربى' },//アラビア語
    { value: 'hi', label: 'हिन्दी' },//ヒンディー語
    { value: 'id' ,label: 'bahasa Indonesia'},//インドネシア語
    { value: 'pt' ,label: 'Português'},//ポルトガル語
    //SNS非対応言語 Linkは英語
    { value: 'fi', label: 'Suomalainen' },//フィンランド語
    { value: 'tr', label: 'İspanyol' },//トルコ語
    { value: 'ms', label: 'Bahasa Melayu' },//マレー語
    { value: 'ta', label: 'தமிழ்' },//タミル語
  ]

// const youtubeLink = {
//   en : "PkpshseQWSc",
//   ja : "MRFdWj4gG7s",
//   es : "wbgVWfWCbvc",
//   ru : "MO6_1h4oIZI",
//   fr : "ghQhkVlyx8s",
//   de : "qS7nlP9-DuA",
//   it : "daACsNQ5v9c",
//   zh : "PaOWje_udgE",
//   ko : "Wk1PwVW2plU",//未修正
//   ar : "0pPb2eNb-Rc",
//   hi : "vEF-i5u-iEY",
//   id : "LCA4Ay8qIhg",//未修正
//   pt : "wYJTgFJOZ3Y",//未修正
// }
  
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      ':active':{backgroundColor:'silver'}
    }),
    singleValue: (provided, state) => {
  
      const opacity = 0;
      const transition = 'opacity 1000ms';
  
      //return { ...provided, opacity, transition };
      return { ...provided };
    }
  }

  export default class LangSelect extends Component {
    state = {
        selectedOption: languages[0],
      };

      constructor(props) {
        super(props);
        this.state = {
          openMenu: false,
          visibilityClass: '',
        };
      }
      toggleMenu = value => {
        this.setState({ openMenu: value });
      };
      
      langChange = (selectedOption) => {
        this.setState({ selectedOption }, () =>{
          //console.log(`Option selected:`, this.state.selectedOption)
          let selectedLang;
          let locaLangage = localStorage.getItem('Langage');

          //SELECTで言語を選択しているか
          if(!selectedOption){
            const languageMap = languages.map(language => language.value);
            let languageCheck = languageMap.includes(locaLangage);
            if(!locaLangage || !languageCheck){
              selectedLang = languages[0].value;
            }else{
              selectedLang = locaLangage;
            }
          }else{
            selectedLang = selectedOption.value;
          }

          //ローカルストレージ保存
          localStorage.setItem('Langage', selectedLang);

          //埋込動画切替
          const movieFlg = document.getElementById("YoutubeUrl");
          if(movieFlg){
            let youtubeIframe = "https://www.youtube.com/embed/";
            if(selectedLang == 'fi' ||
              selectedLang == 'tr' ||
              selectedLang == 'ms' ||
              selectedLang == 'ta'){
              youtubeIframe = youtubeIframe + youtubeLink['en'];
            }else{
              youtubeIframe = youtubeIframe + youtubeLink[selectedLang];
            }
            document.getElementById("YoutubeUrl").src = youtubeIframe;
          }

          // 文字言語表示切替
          const elm = document.getElementsByClassName("langCng");
          for (let i = 0; i < elm.length; i++) {
            // --- 選択された言語と一致は表示、その他は非表示 -------
            if(elm[i].getAttribute("lang") == selectedLang){
              elm[i].style.display = 'block';
            }
            else{
              elm[i].style.display = 'none';
            }
          }
        });
    }

    handleScroll = () => {
        const { visibilityClass } = this.state;
        if (window.pageYOffset > 300) {
          if (visibilityClass !== 'navbar-shrink') {
            this.setState({ visibilityClass: 'navbar-shrink' });
          }
        } else {
          if (visibilityClass === 'navbar-shrink') {
            this.setState({ visibilityClass: '' });
          }
        }
      };

      componentDidMount() {
        this.langChange();
        window.addEventListener('scroll', this.handleScroll);
      }
      componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }

      render() {
        const { selectedOption } = this.state;
        const { openMenu, visibilityClass } = this.state;
    return(
        <Select
        placeholder="Language"
        isSearchable={ false }
        isOptionDisabled={(option) => option.disabled}
        defaultValue={languages[0].value}
        options={languages}
        onChange={this.langChange}
        value={selectedOption}
        className="langSelect"
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'gainsboro',
            primary: 'black',
          },
        })}
        />
    );
      }
  }