export const youtubeLink = {
    en : "PkpshseQWSc",
    ja : "MRFdWj4gG7s",
    es : "wbgVWfWCbvc",
    ru : "MO6_1h4oIZI",
    fr : "ghQhkVlyx8s",
    de : "qS7nlP9-DuA",
    it : "daACsNQ5v9c",
    zh : "PaOWje_udgE",
    ko : "uYKo4dcNWvA",//未修正
    ar : "0pPb2eNb-Rc",
    hi : "vEF-i5u-iEY",
    id : "PVU2NsJxEtY",//未修正
    pt : "4W7DKW0oaHc",//未修正
  }